import { graphql } from "gatsby";
import React from "react";
import Header from "../components/header";
import Layout from "../components/layout/layout";

import PortfolioList from "../components/portfolio/PortfolioList";

export const pageQuery = graphql`
	query {
		allFile(
			filter: {
				sourceInstanceName: {eq: "projects"},
				internal: {mediaType: {eq: "application/octet-stream"}}
			}) {
			edges {
			node {
				id
				absolutePath
				publicURL
				internal {
				contentDigest
				type
				mediaType
				description
				owner
				}
			}
			}
		}
	}
`;

class Portfolio extends React.Component<any, any> {

	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data.allFile.edges,
		};
	}

	public render() {

		console.log(this.props.data.allFile.edges);

		// You can filter your posts based on some criteria
		// const Projects = this.props.data.allFile.edges.filter( (edge) => !!edge.absolutePath ).map((edge) => <Post key={edge.node.id} post={edge.node} /> );
		const Projects = this.props.data.allFile.edges.map( (edge) => <PortfolioList key={edge.node.id} post={edge.node} /> );

		return(
			<React.Fragment>
				<Header />
				<Layout>
					<div className="header">
						<h1>Portfolio</h1>
					</div>
					{Projects}
				</Layout>
			</React.Fragment>
		);

	}
}

export default Portfolio;
