import { Link } from "gatsby";
import React, { CSSProperties } from "react";

class PortfolioList extends React.Component<any, any> {

	constructor(props) {
		super(props);
	}

	public render() {
		console.log(this.props);

		const dateStyle: CSSProperties = {
			 textAlign: "center",
		};

		return(
			<div className="post">
				<Link to={this.props.post.absolutePath}>
					<h2>{this.props.post.publicURL}</h2>
				</Link>
			</div>
		);
	}
}

export default PortfolioList;
